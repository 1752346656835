var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Car"
  }, [_c('BackArrow', {
    attrs: {
      "variant": "white",
      "to": '/cars'
    }
  }), _c('Headline', [_c('br'), _c('h1', [_vm._v("Fahrzeug " + _vm._s(_vm.carId))]), _c('p', [_vm._v(" Hersteller: " + _vm._s(_vm.car.make) + " | Modell: " + _vm._s(_vm.car.model) + " | Baujahr: " + _vm._s(_vm.car.year) + " ")])]), _c('Toolbar', [_c('div'), _c('div', [_c('Button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.car.currentInstallation ? 'Eintrag erstellen' : 'Das Fahrzeug hat kein aktives Gerät.',
      expression: "\n                        car.currentInstallation\n                            ? 'Eintrag erstellen'\n                            : 'Das Fahrzeug hat kein aktives Gerät.'\n                    "
    }],
    attrs: {
      "size": "small",
      "isDisabled": !_vm.car.currentInstallation
    },
    on: {
      "onClick": _vm.openNewEntryModal
    }
  }, [_vm._v("Neuen Eintrag erstellen ")])], 1)]), _c('br'), _c('ServiceEntryTable', {
    attrs: {
      "entries": _vm.actionHistory
    },
    on: {
      "onServiceEntryClick": _vm.handleEntryClick,
      "onServiceEntryRemove": _vm.handleEntryRemove
    }
  }), _c('Modal', {
    attrs: {
      "show": _vm.isModalOpen,
      "isLoading": _vm.isAddingEntry,
      "title": 'Eintrag für Fahrzeug ' + (_vm.car.id || ''),
      "action": _vm.selectedService ? {
        text: 'Speichern',
        color: 'green',
        callback: function callback() {
          return _vm.handleServiceAdd();
        }
      } : {
        text: '',
        color: '',
        callback: function callback() {}
      },
      "cancel": _vm.selectedService ? {
        text: 'Zurück',
        callback: function callback() {
          return _vm.resetSelectedService();
        }
      } : {
        text: 'Abbrechen',
        callback: _vm.closeModal
      }
    },
    on: {
      "onModalClose": _vm.closeModal
    }
  }, [!_vm.selectedService ? _c('p', {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_vm._v(" Bitte wählen Sie den gewünschten Service aus. ")]) : _vm._e(), !_vm.selectedService ? _c('ServiceSelection', {
    attrs: {
      "data": _vm.data
    },
    on: {
      "update-service": _vm.updateSelectedService
    }
  }) : _vm._e(), _vm.selectedService === 'deviceCalibration' ? _c('DeviceCalibration', {
    attrs: {
      "data": _vm.data
    },
    on: {
      "update": _vm.updateData
    }
  }) : _vm._e(), _vm.selectedService === 'engineOilChange' ? _c('EngineOilChange', {
    attrs: {
      "data": _vm.data
    },
    on: {
      "update": _vm.updateData
    }
  }) : _vm._e(), _vm.selectedService === 'carService' ? _c('CarService', {
    attrs: {
      "data": _vm.data
    },
    on: {
      "update": _vm.updateData
    }
  }) : _vm._e(), _vm.selectedService === 'tuvInspection' ? _c('TuvInspection', {
    attrs: {
      "data": _vm.data
    },
    on: {
      "update": _vm.updateData
    }
  }) : _vm._e(), _vm.selectedService === 'transmissionOilChange' ? _c('TransmissionOilChange', {
    attrs: {
      "data": _vm.data
    },
    on: {
      "update": _vm.updateData
    }
  }) : _vm._e(), _vm.selectedService === 'wheelChange' ? _c('WheelChange', {
    attrs: {
      "data": _vm.data
    },
    on: {
      "update": _vm.updateData
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }